var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"padding-bottom":"10px","background-color":"#f7f7f7"}},[_c('el-input',{attrs:{"placeholder":"输入关键字进行过滤","clearable":""},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1),_c('el-tree',{ref:"tree",attrs:{"data":_vm.data,"node-key":"fullpath","expand-on-click-node":false,"empty-text":"空名称","default-expand-all":_vm.isExpand,"filter-node-method":_vm.filterNode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('div',[(_vm.type === 'access' && !data.virtual)?_c('el-checkbox',{class:{ radio: data.data.resourceType == '3' },attrs:{"checked":data.data.select === '1',"disabled":data.data.common || data.data.disable},on:{"change":function($event){return _vm.setAccess(data)}},model:{value:(data.data.select),callback:function ($$v) {_vm.$set(data.data, "select", $$v)},expression:"data.data.select"}}):_vm._e(),(data.data.resourceType == '1')?_c('el-tag',{staticStyle:{"border-radius":"0","margin":"0 2px","transform":"scale(0.9)"},attrs:{"size":"mini","effect":"plain"}},[_vm._v("入口")]):_vm._e(),(data.data.resourceType == '2')?_c('el-tag',{staticStyle:{"border-radius":"0","margin":"0 2px","transform":"scale(0.9)"},attrs:{"size":"mini","type":"warning","effect":"plain"}},[_vm._v("功能")]):_vm._e(),(data.data.resourceType == '3')?_c('el-tag',{staticStyle:{"border-radius":"0","margin":"0 2px","transform":"scale(0.9)"},attrs:{"size":"mini","effect":"plain","type":"success"}},[_vm._v("数据")]):_vm._e(),_c('span',{staticClass:"desc"},[_c('span',[_vm._v(_vm._s(node.label))]),(data.data.resourceType != '0')?_c('span',{staticStyle:{"margin-left":"20px","color":"#888","display":"none"}},[_vm._v(" # "),_c('b',{staticStyle:{"padding-left":"20px"}},[_vm._v("控制标识：")]),_c('i',[_vm._v(_vm._s(data.data.action))]),_c('b',{staticStyle:{"padding-left":"20px"}},[_vm._v("接口：")]),_c('i',[_vm._v(_vm._s(data.data.api || "--"))]),_c('b',{staticStyle:{"padding-left":"20px"}},[_vm._v("描述：")]),_c('i',[_vm._v(_vm._s(data.data.desc || "--"))])]):_vm._e()])],1),_c('span',[(
            _vm.type === 'resource' && data.data && data.data.resourceType !== '0'
          )?_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"text","size":"mini"},on:{"click":function () { return _vm.edit(node, data); }}},[_c('i',{staticClass:"el-icon-edit"}),_vm._v("编辑 ")]):_vm._e(),_c('el-popconfirm',{attrs:{"title":"确定删除这条资源吗？"},on:{"confirm":function () { return _vm.remove(node, data); }}},[(
              _vm.type === 'resource' &&
              data.data &&
              data.data.resourceType !== '0'
            )?_c('el-button',{staticStyle:{"margin-left":"10px","color":"#f56c6c"},attrs:{"slot":"reference","type":"text","size":"mini"},slot:"reference"},[_c('i',{staticClass:"el-icon-delete"}),_vm._v("删除 ")]):_vm._e()],1),(_vm.type === 'resource')?_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"text","size":"mini"},on:{"click":function () { return _vm.append(data); }}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v("新建 ")]):_vm._e()],1)])}}])}),(_vm.addDialogVisible)?_c('AddResource',{attrs:{"dialogFormVisible":_vm.addDialogVisible,"sourceType":_vm.sourceType,"data":_vm.currentData},on:{"update:dialogFormVisible":function($event){_vm.addDialogVisible=$event},"update:dialog-form-visible":function($event){_vm.addDialogVisible=$event},"close":_vm.update}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }