<template>
  <div>
    <div style="padding-bottom: 10px; background-color: #f7f7f7">
      <el-input
        placeholder="输入关键字进行过滤"
        v-model="filterText"
        clearable
      ></el-input>
    </div>
    <el-tree
      :data="data"
      node-key="fullpath"
      :expand-on-click-node="false"
      empty-text="空名称"
      :default-expand-all="isExpand"
      :filter-node-method="filterNode"
      ref="tree"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <div>
          <el-checkbox
            v-model="data.data.select"
            v-if="type === 'access' && !data.virtual"
            :checked="data.data.select === '1'"
            :disabled="data.data.common || data.data.disable"
            @change="setAccess(data)"
            :class="{ radio: data.data.resourceType == '3' }"
          ></el-checkbox>
          <el-tag
            v-if="data.data.resourceType == '1'"
            size="mini"
            style="border-radius: 0; margin: 0 2px; transform: scale(0.9)"
            effect="plain"
            >入口</el-tag
          >
          <el-tag
            v-if="data.data.resourceType == '2'"
            size="mini"
            type="warning"
            style="border-radius: 0; margin: 0 2px; transform: scale(0.9)"
            effect="plain"
            >功能</el-tag
          >
          <el-tag
            v-if="data.data.resourceType == '3'"
            size="mini"
            style="border-radius: 0; margin: 0 2px; transform: scale(0.9)"
            effect="plain"
            type="success"
            >数据</el-tag
          >
          <span class="desc">
            <span>{{ node.label }}</span>
            <span
              v-if="data.data.resourceType != '0'"
              style="margin-left: 20px; color: #888; display: none"
            >
              #
              <b style="padding-left: 20px">控制标识：</b>
              <i>{{ data.data.action }}</i>
              <b style="padding-left: 20px">接口：</b>
              <i>{{ data.data.api || "--" }}</i>
              <b style="padding-left: 20px">描述：</b>
              <i>{{ data.data.desc || "--" }}</i>
            </span>
          </span>
        </div>
        <span>
          <el-button
            type="text"
            size="mini"
            style="margin-left: 10px"
            @click="() => edit(node, data)"
            v-if="
              type === 'resource' && data.data && data.data.resourceType !== '0'
            "
          >
            <i class="el-icon-edit"></i>编辑
          </el-button>
          <el-popconfirm
            title="确定删除这条资源吗？"
            @confirm="() => remove(node, data)"
          >
            <el-button
              slot="reference"
              type="text"
              size="mini"
              style="margin-left: 10px; color: #f56c6c"
              v-if="
                type === 'resource' &&
                data.data &&
                data.data.resourceType !== '0'
              "
            >
              <i class="el-icon-delete"></i>删除
            </el-button>
          </el-popconfirm>
          <el-button
            type="text"
            size="mini"
            style="margin-left: 10px"
            @click="() => append(data)"
            v-if="type === 'resource'"
          >
            <i class="el-icon-plus"></i>新建
          </el-button>
        </span>
      </span>
    </el-tree>
    <AddResource
      v-if="addDialogVisible"
      :dialogFormVisible.sync="addDialogVisible"
      :sourceType="sourceType"
      :data="currentData"
      @close="update"
    ></AddResource>
  </div>
</template>

<script>
import { api } from '../../api';
export const resourceList = api()('resource.list.json');
export const roleLinkResource = api()('role.link.resource.json');
export const roleUnlinkResource = api()('role.unlink.resource.json');
export const resourceDel = api()('/resource.del.json');
let id = 1000;
export default {
  watch: {
    // $route (to, from) {
    //   console.log("watch $route", to, from);
    // },
    $route (to, from) {
      console.log(to, from, 123456);
      this.type = to.query.type;
      this.roleCode = to.query.code ? to.query.code : '';
      if (to.query.type === 'resource') {
        this.isExpand = true
        // console.log(this.isExpand, 'watch');
        this.getData();
      }
    },
    // $route: {
    //   immediate: true, // 一旦监听到路由的变化立即执行
    //   deep: true,
    //   handler () {
    //     console.log(this.$route.query, this.$route.params);
    //     this.type = this.$route.query.type;
    //     this.roleCode = this.$route.query.code ? this.$route.query.code : "";
    //     if (this.$route.query.type === "resource") {
    //       this.isExpand = true
    //       this.getData();
    //     }
    //   },
    // },
    filterText (val) {
      this.$refs.tree.filter(val);
    }
  },
  components: {
    AddResource: () => import('./addResource.vue')
  },
  data () {
    return {
      type: '',
      roleCode: '',
      data: [],
      addDialogVisible: false,
      sourceType: '',
      sourceCode: '',
      fullpath: '',
      currentData: null,
      roleName: '',
      isExpand: false,
      filterText: ''
    };
  },
  created () {
    this.type = this.$route.query.type;
    this.isExpand = this.type === 'resource' ? true : false
    if (this.$route.query.code) {
      this.roleCode = this.$route.query.code;
    }
    if (this.$route.query.name) {
      this.roleName = this.$route.query.name;
    }
    this.getData();
  },
  mounted () {
    if (this.type === 'resource') {
      this.isExpand = true
    }
  },

  methods: {
    filterNode (value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1 || data.data && data.data.action && data.data.action.indexOf(value) !== -1 || data.data && data.data.api && data.data.api.indexOf(value) !== -1;
    },
    async getData () {
      const data = await resourceList({
        roleCode: this.roleCode,
        $ignoreRepeat: true
      });
      this.data = this.dataParse(data);
      // console.log(this.data);
    },

    dataParse (data) {
      const tree = [];
      for (let index = 0; index < data.length; index++) {
        let children = tree;
        const item = data[index];
        const paths = [];
        item.name.split('/').reduce((pv, cv) => {
          pv.push(cv);
          const fullpath = pv.join('/');
          const virtual = fullpath != item.name;
          paths.push({
            label: cv,
            fullpath: fullpath,
            virtual: virtual,
            data: virtual ? { resourceType: '0' } : item,
            children: []
          });
          return pv;
        }, []);
        for (let index = 0; index < paths.length; index++) {
          const item = paths[index];
          const tar = children.find(it => it.label === item.label);
          if (tar) {
            children = tar.children;
            if (!item.virtual) {
              tar.data = item.data;
            }
            tar.virtual = tar.virtual && item.virtual;
          } else {
            children.push(item);
            children = item.children;
          }
        }
      }
      return tree;
    },

    dataTransfer (data) {
      const getTar = path => {
        this.data.find(it => it.name === path) || {};
      };
      const treeDTO = [];
      data.forEach(item => {
        const nodeArray = item.name.split('/');
        const paths = [];
        let children = treeDTO; //[]
        // 循环构建子节点 //[用户,列表,详情]
        for (let index = 0; index < nodeArray.length; index++) {
          const i = nodeArray[index];
          paths.push(i);
          const node = {
            label: i,
            resourceType: '0',
            select: '0',
            fullPath: paths.join('/'),
            ...getTar(paths.join('/'))
          };
          if (children.length === 0) {
            children.push({
              ...node,
              resourceType:
                nodeArray.length - 1 === index ? item.resourceType : '0'
            });
          }
          let isExist = false;
          for (const j in children) {
            if (children[j].label === node.label) {
              children[j] = Object.assign(children[j], node);
              if (!children[j].children) {
                children[j].children = [];
              }
              children = children[j].children;
              isExist = true;
              break;
            }
          }
          if (!isExist) {
            children.push({
              ...node,
              resourceType:
                nodeArray.length - 1 === index ? item.resourceType : '0',
              fullPath: paths.join('/')
            });
            if (!children[children.length - 1].children) {
              children[children.length - 1].children = [];
            }
            children = children[children.length - 1].children;
          }
        }
      });
      return treeDTO;
    },
    // 插入节点
    append (data) {
      this.addDialogVisible = true;
      this.sourceType = 'add';
      this.currentData = data;
    },
    update () {
      this.getData()
      // const newChild = {
      //   id: id++,
      //   label: data.name.split("/").pop(),
      //   virtual: false,
      //   fullPath: data.name,
      //   data: data,
      //   children: []
      // };
      // if (!this.currentData.children) {
      //   this.$set(this.currentData, "children", []);
      // }
      // this.currentData.children.push(newChild);
    },
    // 插入目录
    appendPath (data, type) {
      const text = prompt('请输入路径名称');
      const newChild = {
        id: id++,
        label: text,
        data: { resourceType: type },
        children: []
      };
      if (!data.children) {
        this.$set(data, 'children', []);
      }
      data.children.push(newChild);
    },
    // 编辑节点
    edit (node, data) {
      console.log(node, data, 555);
      this.addDialogVisible = true;
      this.sourceType = 'edit';
      this.currentData = data;
    },

    async remove (node, data) {
      await resourceDel({
        resourceCode: data.data.code
      });
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.fullpath === data.fullpath);
      children.splice(index, 1);
    },

    async setAccess (data) {
      if (data.data.select) {
        await roleLinkResource({
          roleCode: this.roleCode,
          resourceCode: data.data.code
        });
        this.$message.success(`角色-${this.roleName}， 权限-${data.label} 添加成功！`)
      } else {
        await roleUnlinkResource({
          roleCode: this.roleCode,
          resourceCode: data.data.code
        });
        this.$message.error(`角色-${this.roleName}， 权限-${data.label} 已删除！`)
      }
    }
  }
};
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.radio .el-checkbox__inner {
  border-radius: 50%;
  overflow: hidden;
}
</style>
<style lang="scss" scoped>
.margin-left-8 {
  margin-left: 8px;
}
.desc:hover > span {
  display: inline !important;
}
</style>
